.bg-light {
    /*background: #cecece;*/
    background: #F9f9f9 !important;
}
.vh-100 {
    min-height: 100vh;
}
.App-logo {
    height: 60vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #f9f9f9;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.chip-badge {
    position: absolute;
    top: 0;
    left: 15px;
    font-size: 0.7em;
    padding: 2px 7px;
}

.text-muted-info {
    color: #cecece;
    font-size: 0.8rem !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    height: auto !important;
    top: auto !important;
}

.active > .css-1sojhi2-MuiButtonBase-root-MuiButton-root {
    background: #e2e1e1;
}


.bg-target {
    /*background: #f9f9f9;*/
    background: rgba(26, 35, 126, 0.02) !important;
    border-radius: 3px 30px;
    box-shadow: 1px 2px 5px rgba(0,0,0,0.15);
}

@media (max-width: 475px) {
    .bg-target {
        width: 95%;
        margin: auto;
        margin-top: 20px;
        border-radius: 40px;
        box-shadow: 1px 2px 5px rgba(0,0,0,0.15);
    }
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F5F7FF;
}

.table{
    padding: 1.25rem 1.25rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    width: 100%;
}

.table thead th  {
    border-top: 0;
    border-bottom-width: 1px;
    font-weight: 600;
    color:#1A237E;
}
.table thead th, .jsgrid .jsgrid-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #1A237E;;
}

.table tbody td tr{
    padding: 1.25rem 1.25rem;
}
.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    color: #212529;
}

.to-right {
    margin-left: 80%;
}
.to-left {
    margin-right: 80%;
}
.flex {
    display: flex;
    /* width: 50%; */
  /* flex-direction: column; */
    align-items: center;
}
.change-color{
    background-color: red;  
}
.reportLink{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    color: #1A237E;
    font-weight: 600;
    cursor: pointer;
}

.tableTitle{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 22px;
    color: #1A237E;
    font-weight: 600;
}

.cardsContainer{
  height: 100%;
  justify-content: space-between;
}
.h-56 {
    height: 100%;
  }
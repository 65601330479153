h1{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 22px;
    color: #1A237E;
    font-weight: 600;
    cursor: pointer;
}

p{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    color: #4e4e4e;
    font-weight: 400;
    padding-left: 30px;
}
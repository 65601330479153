.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .flex > div {
    flex-basis: 49%;
  }
  
h3{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    color: #1A237E;
    font-weight: 500;
}
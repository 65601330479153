.body{
    background-color: #f9f9f9;
}
.report-style-class {
    height: 65vh;
    margin: 1% auto;
    width: 835px;
}

.report-loading {
    display: none;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F5F7FF;
}

.progress {
    border-radius: 7px;
    height: 30px; 
}

.table{
    padding: 1.25rem 1.25rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    width: 100%;
}

.table thead th  {
    border-top: 0;
    border-bottom-width: 1px;
    font-weight: 600;
    color:#1A237E;
}
.table thead th, .jsgrid .jsgrid-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #1A237E;;
}

.table tbody td tr{
    padding: 1.25rem 1.25rem;
}

.Card{
    width: 60vw;
    padding: 10px;
}

.bigContainer{
    background-color: #f9f9f9;
}
.card-survey {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .card-survey > .card-item-first {
        flex: 100%;
    }
    .card-survey > .card-item-survey {
        flex: 33.33%;
        text-align: center;
        margin-top: 10px;
    }
    .card-item-last {
        flex: 100%;
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        border-left: none !important;
    }
}
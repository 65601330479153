.progress-bar-container {
    height: 20px;
    width: 100%;
    background-color: lightgray;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}


.progress-bar {
    height: 100%;
}

.progress-bar-value {
    position: absolute;
    align-items: center;
    justify-content: center;

    margin-left: 1%;

    color: black;
}
.row-title {
    font-size: 12px;
    font-weight: normal;
  }
  .row-title {
    font-size: 12px;
    font-weight: normal;
  }
  .matrix-square:hover {
    filter: brightness(90%);
  }
     
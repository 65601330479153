.mitad {
    width: 50%;
}
.container {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.job-security {
    font-size: 20px;
}

.value {
    font-size: 16px;
}

.danger {
    color: #FB5523;
}

.warning {
    color: #FDD835;
}

.success {
    color: #6DAE43;
}
  
.buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  align-items: center;
  margin: 0 auto;

}
.copy-to-clipboard {
  display: flex;
}

.alert-style {
  display: block;
  position: fixed;
  top: 70px;
  right: 0.6rem;
  z-index: 1500;
}
